import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import seoImage from './../../favicon.png'
import axios from '../../axios'
import { ENDPOINTS } from '../../constants'
import { UAParser } from 'ua-parser-js'
import { setItemToLocalStorage } from '../../../utils/setLocalStorage'
declare const window: any

const SEO = ({ title, description, image, article, klaviyo }) => {
  const { pathname, search } = useLocation()
  let { site } = useStaticQuery(query)
  if (title === 'How it Works | Make Cash Back for Shopping & Sharing') {
    site.siteMetadata.titleTemplate = '%s'
  }

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  let emailAccount = ''
  if (window.localStorage.getItem('profile')) {
    emailAccount = JSON.parse(window.localStorage.getItem('profile'))?.email
  }

  const siteUrl = process.env.GATSBY_SITE_URL

  if (emailAccount) {
    if (!window.klaviyoTrigger) {
      window.klaviyoTrigger = function(
        triggerName: string,
        productId: any,
        productName: any
      ) {
        var _learnq = window._learnq || []
        _learnq.push([
          'track',
          `${triggerName}`,
          {
            productId: productId,
            productName: productName.replace(/"/g, ''),
          },
        ])
      }
    }
    if (!window.itemsFavourite) {
      window.itemsFavourite = function(itemName: any, items: any, value: any) {
        var _learnq = window._learnq || []
        _learnq.push([
          'track',
          'Items Favourite',
          {
            item_name: itemName.replace(/"/g, ''),
            items: items,
            $value: value,
          },
        ])
      }
    }
  }

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${seoImage}`,
    url: `${siteUrl}${pathname}`,
  }

  if (emailAccount && pathname === '/user/personal-info') {
    if (
      window.localStorage.getItem('personalInfoTrigger') === null ||
      window.localStorage.getItem('personalInfoTrigger') !== 'true'
    ) {
      axios.patch(`${ENDPOINTS.klaviyoTrigger}`, {
        email: emailAccount,
        event: 'Joined',
        body: {
          browser: new UAParser().getBrowser().name,
          os: new UAParser().getOS().name,
        },
      })
      setItemToLocalStorage('personalInfoTrigger', 'true')
    }
  }

  return (
    <>
      <Helmet
        title={seo.title}
        titleTemplate={titleTemplate}
        link={[{ rel: 'canonical' }]}
      >
        <html lang="en" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta name="description" content={seo.description} />
        <meta name="robots" content="index,follow" />
        <meta name="image" content={seo.image} />

        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Chirpyest" />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.image && <meta property="og:image" content={seo.image} />}
        {seo.image && <meta property="og:image:alt" content="chirpyest" />}
        {seo.image && (
          <meta property="og:image:secure_url" content={seo.image} />
        )}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}
        <meta
          property="og:type"
          content={location.pathname === '/' ? 'website' : 'article'}
        />

        <meta property="twitter:site_name" content="Chirpyest" />
        <meta property="twitter:card" content="summary_large_image" />
        {seo.title && <meta property="twitter:title" content={seo.title} />}
        {seo.url && <meta property="twitter:url" content={seo.url} />}
        {seo.image && <meta property="twitter:image" content={seo.image} />}
        {seo.image && <meta property="twitter:image:alt" content="chirpyest" />}
        {twitterUsername && (
          <meta property="twitter:creator" content={'@' + twitterUsername} />
        )}
        {seo.description && (
          <meta property="twitter:description" content={seo.description} />
        )}

        {process.env.GATSBY_APP_NAME === 'chirpyest-dev' && (
          <meta name="robots" content="noindex" />
        )}

        {location.pathname.startsWith('/p/') && (
          <link rel="canonical" href={location.href} />
        )}

        {/* Global site tag (gtag.js) - Google Analytics */}

        {siteUrl === 'https://www.chirpyest.com' && (
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-3CM9M8HM5Q"
          />
        )}
        {siteUrl === 'https://www.chirpyest.com' && (
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-3CM9M8HM5Q');
          `}
          </script>
        )}

        {pathname === '/user/personal-info' &&
          siteUrl === 'https://www.chirpyest.com' && (
            <script>
              {`
              gtag('event', 'conversion', {'send_to': 'AW-10856744977/i4uECM6LzOEDEJGQ87go'});
            `}
            </script>
          )}

        {pathname === '/join/' &&
          search === '?fromExtension=true' &&
          siteUrl === 'https://www.chirpyest.com' && (
            <script>
              {`
              gtag('event', 'conversion', {'send_to': 'AW-10856744977/rQlhCIeDmuEDEJGQ87go'});
            `}
            </script>
          )}

        {/* fix PWA redirect after instagram autherization */}
        <script>
          {`
            if (!!navigator.platform && /iP(?:hone|ad|od)/.test(navigator.platform)) {
              document.querySelector('link[rel="manifest"]').setAttribute('rel', 'no-ios');
              document.title = 'Chirpyest';
              if ('standalone' in window.navigator && window.navigator.standalone && sessionStorage.getItem('iOS-redirect') === null) {
                sessionStorage.setItem('iOS-redirect', '');
                // window.location = '/';
              }
            }
          `}
        </script>

        {/* Tiktok Pixel Code */}
        {siteUrl === 'https://www.chirpyest.com' && (
          <script>
            {`
              !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
                ttq.load('CDR6P9RC77UF0POAT0V0');
                ttq.page();
              }(window, document, 'ttq');
          `}
          </script>
        )}

        {siteUrl === 'https://www.chirpyest.com' && (
          <script
            async
            type="text/javascript"
            src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=RZJXDw"
          ></script>
        )}

        {siteUrl === 'https://www.chirpyest.com' && emailAccount && (
          <script>
            {`
              var _learnq = _learnq || []; 
              _learnq.push(['identify', {'$email' : '${emailAccount}'}]);
            `}
          </script>
        )}

        {klaviyo?.triggerName === 'Product Clicked' &&
          emailAccount &&
          siteUrl === 'https://www.chirpyest.com' && (
            <script>
              {`
                var _learnq = _learnq || [];
                _learnq.push([
                  "track",
                  "${klaviyo.triggerName}",
                  {
                    productId: "${klaviyo.productId}",
                    productName: "${klaviyo.productName.replace(/"/g, '')}"
                  }
                ]);
              `}
            </script>
          )}
      </Helmet>
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  klaviyo: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  klaviyo: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle
        titleTemplate
        defaultDescription
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
